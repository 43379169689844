import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  MainHeading,
  Span,
  SubHeadingStyled,
  TextStyled,
} from "../../Styled/Elements";
import { FaqsWrapper } from "./Styled";
import { ArrowDown, ArrowUp } from "../../Styled/AllImages";
import { faqData } from "../../data/Data";

const Faqs = () => {
  const { t, i18n: {language} } = useTranslation();
  const [activeItem, setActiveItem] = useState(0);
  const toggleItem = (index) => {
    setActiveItem(activeItem === index ? null : index);
  };

  return (
    <>
      <FaqsWrapper className="container-fluid common-space faqsFluid pt-5" lang={language} id="faqs">
        <Container>
          <Row className="faqsRow">
            <Col md={12}>
              <Box className="mb-3">
                <MainHeading className="main-heading justify-content-center" size="36px" color="var(--text)">
                  {t("frequently_asked")}
                </MainHeading>
              </Box>
            </Col>
            <Col lg={12} className="sliderWrapper">
              {faqData.map((list, index) => (
                  <Box
                    key={index}
                    className={`item-container  ${
                      activeItem === index ? "active-item-box" : ""
                    }`}
                    onClick={() => toggleItem(index)}
                  >
                    <Box className="item-box">
                      <Span className="d-flex gap-2 align-items-center item-box-heading">
                        {t(list.question)}
                      </Span>
                      {activeItem === index ? <ArrowUp /> : <ArrowDown />}
                    </Box>
                    {activeItem === index && (
                      <Box>
                        <Box>
                          <TextStyled className="item-box-text">
                            {t(list.answer)}
                          </TextStyled>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
            </Col>
          </Row>
        </Container>
      </FaqsWrapper>
    </>
  );
};

export default Faqs;
