import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Box, HeadingStyled, LabelStyled } from "../../../Styled/Elements";
import { useTranslation } from "react-i18next";
import {
  ButtonCustom,
  InputStyled,
  SecondaryInputStyled,
  SecondaryTextAreaStyled,
  SelectionDropdown,
} from "../../../Styled/CustomElements";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Cancel } from "../../../Styled/AllImages";

function CustomModal({ show, setShow, title }) {
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const options = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
    { label: "Option 3", value: "3" },
  ];

  return (
    <ModalWrapper>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="contact-modal-heading justify-content-between px-lg-4 px-md-3 px-2">
          <HeadingStyled size="24px" weight="bold" color="var(--text)">
            {title}
          </HeadingStyled>
          <Box>
            <Cancel onClick={handleClose} style={{cursor: "pointer"}}/>
          </Box>
        </Modal.Header>
        <Modal.Body className="px-lg-4 px-md-3 px-2">
          <Box>
            <Row>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("first_name")}
                  </LabelStyled>
                  <SecondaryInputStyled
                    name="first_name"
                    placeholder={t("namePlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("last_name")}
                  </LabelStyled>
                  <SecondaryInputStyled
                    name="last_name"
                    placeholder={t("namePlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("work_email")}
                  </LabelStyled>
                  <SecondaryInputStyled
                    name="work_email"
                    placeholder={t("emailPlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("role")}
                  </LabelStyled>
                  <SelectionDropdown
                    options={options}
                    name="role"
                    placeholder={t("select_one")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("country_region")}
                  </LabelStyled>
                  <SelectionDropdown
                    options={options}
                    name="country_region"
                    placeholder={t("select_one")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("company")}
                  </LabelStyled>
                  <SecondaryInputStyled
                    name="role"
                    placeholder={t("companyPlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("company_size")}
                  </LabelStyled>
                  <SelectionDropdown
                    options={options}
                    name="role"
                    placeholder={t("select_one")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("phone_number")}
                  </LabelStyled>
                  <SecondaryInputStyled
                    name="phone_number"
                    placeholder={t("phonePlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("how_can_sales_team_help")}
                  </LabelStyled>
                  <SelectionDropdown
                    options={options}
                    name="how_can_sales_team_help"
                    placeholder={t("select_one")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
              <Col lg={12}>
                <Box className="field-box">
                  <LabelStyled color="var(--text)" size="16px" weight="700">
                    {t("anything_else")}
                  </LabelStyled>
                  <SecondaryTextAreaStyled
                    name="anything_else"
                    placeholder={t("messagePlaceholder")}
                    border="var(--border)"
                  />
                </Box>
              </Col>
            </Row>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <ButtonCustom label={t("cancel")} onClick={handleClose} bg="var(--bgSecondary)" color="var(--text2)"/>
          <ButtonCustom label={t("submit")} onClick={handleClose} bg="var(--themeColor)" color="var(--white)"/>
        </Modal.Footer>
      </Modal>
    </ModalWrapper>
  );
}

export default CustomModal;
const ModalWrapper = styled.div`
    .btn-close{
        margin: 0!important;
    }
`