import { Link } from "react-scroll";
import { Box, LazyImage } from "../Styled/Elements";
import { HeaderContainer } from "./Styled";
import { NavDataConfig } from "../data/Data";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsJustify, BsX } from "react-icons/bs";
import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { LogoEn, LogoAr } from "../Styled/AllImages";
import { ContactButton } from "../Styled/CustomElements";
import LangChangeButton from "../../Languages/LangChangeButton";

const Header = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
    const element = document.querySelector(".nav-bar-list");
    element.classList.toggle("showToggle");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <HeaderContainer
        className={`${isSticky ? "sticky" : ""} container-fluid`}
      >
        <Container>
            <Box className="nav-bar">
              <Box className="logo" onClick={() => navigate("/")}>
                {language === "ar" ? (
                  <LazyImage src={LogoAr} />
                ) : (
                  <LazyImage src={LogoEn} />
                )}
              </Box>
              <Box className="nav-bar-list">
                {NavDataConfig.map((item, key) => (
                  <Box key={key}>
                    <Link
                      to={item.to}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      activeClass="active"
                      onClick={handleToggle}
                    >
                      {t(item.label)}
                    </Link>
                  </Box>
                ))}
              </Box>
              <Box className="whtLngWrapper d-flex align-items-center justify-content-center">
                <ContactButton label={t('sign_in')} bg="var(--white)" color="var(--text)" clName={"signin-btn"}/>
                <ContactButton label={t('sign_up_for_free')} color="var(--white)" bg="var(--themeColor)" clName={"signup-btn"}/>
                <LangChangeButton />
                <span className="toggleBtn" onClick={handleToggle}>
                  {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                </span>
              </Box>
            </Box>
        </Container>
      </HeaderContainer>
    </>
  );
};

export default Header;
