import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const Box = styled.div`
    background-color: ${({ bg }) => bg ? bg : ''};
`
export const Span = styled.span`
    background-color: ${({ bg }) => bg ? bg : ''};
`

export const IconBoxColor = styled.div`
    svg {
        [stroke] {
            stroke: ${({ iconColor }) => iconColor ? iconColor : ''};
        }
    }
`

export const LazyImage = (props) => {
    return (
        <LazyLoadImage className="custom-img"
            {...props}
            effect="blur"
        />
    )
}


export const SpanStyled = styled.span`
    color:${props => props.color ? props.color : ""};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : ''};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
    
`

export const HeadingStyled = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : '20px'};
    display: ${props => props.display ? props.display : ''};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`
export const SubHeadingStyled = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : '24px'};
    display: ${props => props.display ? props.display : ''};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`
export const MainHeading = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => props.lh ? props.lh : 'normal'};
    font-size:${props => props.size ? props.size : '36px'};
    display: ${props => props.display ? props.display : 'flex'};
    width: ${props => props.display ? props.display : '100%'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`
export const TextStyled = styled.p`
    display: ${props => props.display ? props.display : 'flex'};
    width: ${props => props.display ? props.display : '100%'};
    line-height: ${props => props.lh ? props.lh : '24px'};
    font-size:${props => props.size ? props.size : '15px'};
    font-weight:${props => props.weight ? props.weight : ''};
    color:${props => props.color ? props.color : "var(--text)"};
    text-transform: ${props => props.transform ? props.transform : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`

export const LabelStyled = styled.label`
    line-height:${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : '14px'};
    font-weight:${props => props.weight ? props.weight : '500'};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    color:${props => props.color ? props.color : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`

export const ButtonStyled = styled.button`
    transition: 0.3s ease-in-out opacity;
    font-size:${props => props.size ? props.size : '16px'};
    font-weight:${props => props.weight ? props.weight : '500'};
    background:${props => props.bg ? props.bg : "none"};
    color: ${props => props.color ? props.color : "var(--ThemeColor)"};
    padding: ${props => props.padding ? props.padding : "11px 20px"};
    border: ${props => props.border ? props.border : "none"};
    width: ${props => props.width ? props.width : "100%"};
    line-height: ${props => props.lineHeight ? props.lineHeight : "normal"};
    border-radius: ${props => props.radius ? props.radius : "8px"};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--arRegular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--regular)"};
    
`