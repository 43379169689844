import styled from "styled-components";

export const ContactWrapper = styled.section`
    .contact-box{
        padding: 2rem 3rem;
        background: var(--themeGradient);
        border-radius: 16px;
    }
    .contact-links.col{
        flex: 0!important;
    }
    .social-icon{
    background-color: #ffffff33;
    border-radius: 9px;
    width: 42px;
    height: 42px;
  }
`